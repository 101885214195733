<template>
  <span>
    {{ $t(title) }}
  </span>
</template>

<script>
export default {
  props: ['title']
}
</script>
